body {
  margin: 0;
  background-color: aliceblue;
  font-family: PT Serif;
}

.hero img {
  height: 100vh;
}

.carousel-caption h1 {
  /* font-family: 'Kalam', cursive; */
  font-family: 'Yanone Kaffeesatz';
  font-weight: bold;
  letter-spacing: 2px;
  text-shadow: 2px 2px 3px rgba(255, 0, 0, 1);
}

.carousel-caption p {
  font-family: "PT Serif";
  font-size: 1.4rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}

.carousel-indicators {
  visibility: hidden;
}

.carousel-indicators .active {
  /* background-color: #333333; */
  background-color: #fa1717;
  width: 60px;
  height: 5px;
  border-radius: 50%;
}

.carousel-control-prev-icon{
  /* background-color: #333333; */
  background-color: #fa1717;
  height: 100px;
  border-radius: 50% 0 0 50%;
  visibility: hidden;
}

.carousel-control-next-icon {
  /* background-color: #333333; */
  background-color: #fa1717;
  height: 100px;
  border-radius: 0 50% 50% 0;
  visibility: hidden;
}

.navbar {
  background-color: #333333;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  transition: background-color 0.4s;
}

.navbar.active {
  background-color: #fd4444;
}

.container-fluid {
  padding: 0;
}

.navbar-brand {
  font-family: 'Kalam', cursive;
  font-weight: 700;
  font-size: 1.3em !important;
  padding: 5px 10px;
  /* border-bottom: 2px solid rgba(63, 63, 63, 0.7); */
}

a.nav-link {
  padding: 2px 10px;
  /* border-bottom: 2px solid rgba(63, 63, 63, 0.7); */
}

.navbar-brand span img {
  border-radius: 50%;
  margin-right: 5px;
}

/* .navbar-brand .brand-name {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
} */

.navbar-dark .navbar-nav .nav-link {
  font-family: 'Yanone Kaffeesatz';
  color: #e2e2e2;
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.dropdown-menu {
  --bs-dropdown-color: rgba(255, 0, 0, 0.7);
  --bs-dropdown-bg: #ff6060;
  --bs-dropdown-border-color: #333333;
  --bs-dropdown-link-color: #fff;
}

.navbar.active .dropdown-menu {
  --bs-dropdown-color: rgba(255, 0, 0, 0.7);
  --bs-dropdown-bg: #333333;
  --bs-dropdown-border-color: rgba(255, 0, 0, 0.4);
  --bs-dropdown-link-color: #fff;
}

.highlights {
  /* background-color: #333333; */
  background-color: #ff6060;
  color: whitesmoke;
}

.highlights .row {
  margin: 0;
}

.highlights h2 {
  font-family: 'Kalam', cursive;
  background: url(assets/images/background/project-title.png) no-repeat center center;
  margin-bottom: 25px;
}

.highlights video {
  max-width: 98%;
  border-radius: 10px;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.4);
}

.about {
  margin: 20px 0 30px;
  padding: 0 12px;
}

.about h2 {
  font-family: 'Kalam', cursive;
  background: url(assets/images/background/our-app-bg.png) no-repeat center center;
  font-weight: bold;
  text-shadow: 2px 2px 2px rgba(255, 96, 96, 0.4);
  padding: 0;
}

.about h4 {
  font-family: Montserrat;
}

.about-collage-image {
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-collage-image .img-fluid {
  border-radius: 5px;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3);
}

.about-collage-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  margin-right: 5%;
  padding: 0 25px;
}

.about-collage-info img {
  width: 24px;
  height: 24px;
}

.about-collage-info p {
  font-family: 'Montserrat', sans-serif;
  margin: 0 10px 0 0;
}

.about-collage-info a {
  text-decoration: none;
}

.about-collage-info .about-vision {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0;
}

.latest {
  /* position: relative; */
  /* background-color: #333333; */
  background-color: #ff6060;
  color: whitesmoke;
  text-align: center;
  padding: 30px 12px;
}

/* SVG ELEMENT IF AVAILABLE */

/* .svg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.svg1 {
  position: absolute;
  top: 80px;
  left: 20px;
  width: 40%;
  height: 40%;
  opacity: 0.5;
}

.svg2 {
  position: absolute;
  bottom: 80px;
  right: 20px;
  width: 40%;
  height: 40%;
  opacity: 0.5;
} */

.latest .new-product-title {
  margin-bottom: 20px;
  padding: 30px 0 10px;
}

.latest h2 {
  font-family: 'Kalam', cursive;
  margin-bottom: 15px;
  background: url(assets/images/background/project-title.png) no-repeat center center;
  font-weight: bold;
  text-shadow: 2px 2px 2px rgba(255, 96, 96, 0.4);
  padding: 0;
}

.latest .image-container {
  background: #fff none repeat scroll 0 0;
  position: relative;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.latest .image-title {
  color: #fff;
  bottom: 0;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 50px;
  bottom: 0;
  transition: .4s;
}

.latest .image-container .image-title h5 {
  background: rgba(51, 51, 51, 0.8);
  color: #fff;
  line-height: 35px;
  padding: 8px 40px;
  font-family: cursive;
}

.latest .image-title a {
  text-decoration: none;
  color: white;
}

.latest .image-container:hover .image-title {
  bottom: 1rem;
}

.latest .image-container:hover a {
  color: #ff6060;
  text-shadow: 2px 2px 3px rgba(245, 245, 245, 0.322);
}

.feature {
  min-height: 500px;
  padding: 30px 12px 20px;
}

.feature h2 {
  font-family: 'Kalam', cursive;
  margin-bottom: 15px;
  background: url(assets/images/background/our-app-bg.png) no-repeat center center;
  font-weight: bold;
  text-shadow: 2px 2px 2px rgba(255, 96, 96, 0.4);
  padding: 0;
}

/* .feature-row {
  position: relative;
} */

.single-feature {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.single-feature-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;  
  border-radius: 10px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4), 2px 2px 10px rgba(255, 255, 255, 0.4);
  position: relative;
  top: 0px;
  transition: 0.3s ease-in-out;
}

.single-feature-container:hover {
  top: -15px;
}

.feature .icon {
  width: 60px;
  height: 80px;
  margin: 10px 0 20px;
}

.feature h5 {
  font-family: 'Montserrat';
  margin-bottom: 10px;
}

.location {
  /* background-color: #333333; */
  background-color: #ff6060;
  color: whitesmoke;  
  padding: 0 12px;
}

.location h2 {
  font-family: 'Kalam', cursive;
  background: url(assets/images/background/project-title.png) no-repeat center center;
  margin-bottom: 15px;
  font-weight: bold;
  text-shadow: 2px 2px 2px rgba(255, 96, 96, 0.4);
  padding: 0;
}

.location p {
  margin-bottom: 30px;
}

.map-container {
  max-width: 100%;
  height: 500px;
  border-radius: 15px;
  box-shadow: 4px 4px 6px rgba(255, 255, 255, 0.6), 5px 5px 10px rgba(0, 0, 0, 0.6);
}

.cta {
  margin-top: 20px;
  padding: 30px 12px 20px;
}

.cta h2 {
  font-family: 'Kalam', cursive;
  background: url(assets/images/background/our-app-bg.png) no-repeat center center;
  margin-bottom: 15px;
  font-weight: bold;
  text-shadow: 2px 2px 2px rgba(255, 96, 96, 0.4);
  padding: 0;
}

.cs-row {
  display: flex;
  justify-content: center;
}

.cs-container {
  position: relative;
  max-width: 300px;
  margin: 5px;
  background-color: #333333;
  border-radius: 10px;
  /* box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3); */
  box-shadow: 3px 3px 8px rgba(255, 96, 96, 0.4);
  cursor: pointer;
  overflow: hidden;
  color: whitesmoke;
  transition: 10ms ease-in-out 0.1s;
}

.cs-container:hover {
  color: #000;
}

.cs-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  transition:0.2s ease-out;
  background-color: #ff7f7f;
}

.cs-container:hover::before {
  top: 0;
}

.cs-container .wa-button {
  position: relative;
  font-size: 3em;
  color: rgb(37, 211, 102);
  padding: 10px 0;
  border-radius: 50%;
  z-index: 1;
}

.cs-container:hover .wa-button {
  color: rgb(26, 146, 70);
}

.cs-container p {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  z-index: 1;
}

.cs-container span {
  font-family: cursive;
}

.footer {
  background-color: #7e2020;
  padding: 20px 12px;
}

.footer-about a,
.footer-products a {
  text-decoration: none;
  color: rgb(165, 165, 165);
}

.footer-social-icons {
  list-style: none;
}

.footer-social-icons ul {
  padding: 0;
}

.footer-social-icons li {
  display: inline-block;
  margin-right: 1rem;
  width: 24px;
}

.footer-social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
}

.footer-social-icons a:hover {
  color: #ccc;
}

.footer-social-icons svg {
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

@media (min-width: 992px) {
  .about-collage-info img {
    width: 32px;
    height: 32px;
  }
  .about-collage-info p {
    font-size: 1.1rem;
  }
  .location p {
    font-size: 1.1rem;
  }
  .cta p {
    font-size: 1.1rem;
  }
  .highlights video {
    max-width: 90%;
  }
}

@media (max-width: 992px) {
  .carousel-caption h3 {
    font-size: 2.5rem;
  }
  .carousel-caption p {
    font-size: 1.2rem;
  }
  .highlights video {
    max-width: 98%;
  }
}

@media (max-width: 768px) {
  .carousel-caption h3 {
    font-size: 2rem;
  }
  .carousel-caption p {
    font-size: 1rem;
  }
  .about-collage-image {
    margin-left: 0;
  }
  .about-collage-info {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  .carousel-caption h3 {
    font-size: 1.5rem;
  }
  .carousel-caption p {
    font-size: 0.8rem;
  }
  .about-collage-image {
    margin-left: 0;
  }
  .about-collage-info {
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .svg-wrapper svg {
    opacity: 0.2;
  }
}

@media only screen and (max-width: 576px) {
  .svg-wrapper {
    display: none;
  }
}